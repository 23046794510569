import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import '../generic-page.scss';
import './pvp.scss';
import { Row, Col, Button } from 'react-bootstrap';
import { Box } from '../../modules/common/components/box';
import { YoutubeEmbed } from '../../modules/common/components/youtube-embed';
import { SectionHeader } from '../../modules/common/components/section-header';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StaticImage } from 'gatsby-plugin-image';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const HSRPVPHub: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page pvp-hub pvp'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>PVP Draft Mode</li>
      </ul>
      <div className="page-content">
        <div className="image-header top">
          <Box>
            <Row>
              <Col>
                <div className="inner">
                  <h1>Check out the Honkai: Star Rail PVP Draft mode</h1>
                  <h2>
                    Do you want to challenge other players in Honkai: Star Rail?
                    Look no further and try the Prydwen's PVP Draft Mode!
                  </h2>
                </div>
              </Col>
            </Row>
          </Box>
        </div>
        <h5>What is PVP Draft?</h5>
        <p>
          Born out of the desire to put your roster and favorite units to the
          test and{' '}
          <strong>see who can clear Memory of Chaos 10 the fastest</strong>{' '}
          Prydwen has created the Honkai: Star Rail Community Draft Game Mode.
          Find a friend and team up with each of you clearing one half of MoC 10
          combine your scores and see if you can be quicker than your opponent!
        </p>
        <p>Here's how it works:</p>
        <ul>
          <li>
            <strong>Two teams of two</strong> battle it out to see who can clear
            memory of Chaos 10 the fastest,
          </li>
          <li>
            Each team drafts a total of{' '}
            <strong>
              8 units to construct 2 separate compositions (one for each player)
            </strong>
            ,
          </li>
          <li>
            One player on the team will clear Memory of Chaos 10 side 1 and the
            other will clear side 2.{' '}
            <strong>
              The team that clears both sides with the fewest cycles combined
              wins
            </strong>
            .
          </li>
        </ul>
        <h5>Draft</h5>
        <p>
          Before the battle starts when drafting here’s what you need to know:
        </p>
        <ul>
          <li>
            Both teams get an opportunity to <strong>ban two characters</strong>{' '}
            at different points of the draft for a total of 4 bans,
          </li>
          <li>
            Each team must pick{' '}
            <strong>
              8 characters and use all of them in their compositions
            </strong>
            ,
          </li>
          <li>
            Characters drafted by the opposing team cannot be drafted by your
            own team.
          </li>
        </ul>
        <h5>The Point System</h5>
        <p>
          Each team gets <strong>30 points</strong> to spend on 5★ characters,
          signature Light Cones, their Superimpositions and Eidolons.
        </p>
        <ul>
          <li>
            All 5★ characters chosen cost <strong>2</strong> point,
          </li>
          <li>
            All 5★ signature Light Cones cost <strong>2</strong> point,
          </li>
          <li>
            Higher Superimpositions and Eidolons cost a variety of additional
            points depending on their power level,
          </li>
          <li>
            4★ characters, Light Cones and Herta Store Light Cones{' '}
            <strong>do not cost points</strong>,
          </li>
          <ul>
            <li>But some, stronger, 4★ characters do cost points,</li>
          </ul>
          <li>
            For every 6 points under 30 a team’s final total is they have 1
            total cycle subtracted off their score,
          </li>
          <li>
            For every 2 points over 30 an additional cycle is added to their
            final score,
          </li>
          <li>
            In the event of a tie in cycles the team with the lower point total
            wins.
          </li>
        </ul>
        <h5>Preparing for battle</h5>
        <p>
          After the draft is complete, Eidolons and Signatures have been tallied
          and the final cycle bonuses or penalties have been determined the 4
          players will gear up their teams for Memory of Chaos 10 and begin
          battle!
        </p>
        <p>
          If you want to see an example PVP draft look like, we recorded a video
          showcasing it (with guest commentary from MrPokke and Grimro):
        </p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="Qtg6QowhPgY" />
          </Col>
        </Row>
        <SectionHeader title={'Keeping things fun'} />
        <div className="image-header">
          <Box>
            <Row>
              <Col>
                <div className="inner">
                  <h2>Including as many as possible & keeping it fun</h2>
                </div>
              </Col>
            </Row>
          </Box>
        </div>
        <p>
          Honkai Star Rail is a game in which additional power is easily
          accessible by spending.{' '}
          <strong>
            Our goal with this mode was to ensure it was fun and playable by the
            largest number of players possible
          </strong>{' '}
          while still allowing you to use your favorites on your account within
          reason. As a result of this here are some guidelines for the mode we
          intend to aim to stick to.
        </p>
        <ul>
          <li>
            <strong>E0 and E1</strong> characters point costs will be balanced
            with the intent to make them competitively fair,
          </li>
          <li>
            <strong> E2 and E3</strong> character point costs will be balanced
            to be useable but impose a slight disadvantage,
          </li>
          <li>
            <strong>E4, E5 and E6</strong> character point costs will be
            balanced to impose a severe disadvantage.
          </li>
        </ul>
        <p>
          As the game mode continues to develop we will continue to try to keep
          things fair when it comes to eidolons, signature Light Cones and any
          exceptionally powerful characters but the goal is always to ensure as
          many people as possible can have fun.
        </p>
        <h5>Time and roster size</h5>
        <p>
          We feel quite confident that the best strategy to win currently is to
          use as few Eidolons and Signatures as possible to acquire cycle
          reductions to your final time.
        </p>
        <p>
          However one large advantage that only time can solve though is how
          good the relics on your account are or how large a roster of
          characters you have access to. As we get more characters and people
          have more time to level them and farm relics we expect the playing
          field to even out between those who have just started and those who
          have played a long time or used stamina refreshes.
        </p>
        <SectionHeader title={'How to compete!?'} />
        <div className="image-header">
          <Box>
            <Row>
              <Col>
                <div className="inner">
                  <h2>How to compete!?</h2>
                </div>
              </Col>
            </Row>
          </Box>
        </div>
        <p>
          If you’ve read all this and want to get started playing right away
          well we’ve got you covered! We’ve cooked up a{' '}
          <strong>
            PVP discord server intended for players to battle it out with their
            2v2 teams and even developed an ELO bot that tracks your rank with a
            Leaderboard for those looking to get competitive.
          </strong>{' '}
          To get started here is what we recommend:
        </p>
        <ul>
          <li>
            Watch the <strong>pvp draft video</strong> casted by Mr Pokke &
            Grimro to get an idea how the mode plays out and support the
            concept!
          </li>
          <li>
            Find a partner to team up with who preferably has different units
            than you do built so you have more options when drafting!
          </li>
          <li>
            Be at Memory of Chaos 10 and able to 3 star it to ensure you’ve got
            a shot at being competitive!
          </li>
          <li>
            Join the <strong>PVP discord server</strong> and read the rules and
            instructions on how to join a match and get started on PVP!
          </li>
        </ul>
        <p>Here's how the Draft Tool looks like:</p>
        <StaticImage
          src="../../images/starrail/generic/drafttool.webp"
          alt="Draft"
        />
        <p>And you can find it here:</p>
        <OutboundLink href="https://starrailpb.dev/solo/game" target="_blank">
          <Button variant="primary">PVP Draft Tool</Button>
        </OutboundLink>
        <br /> <br />
        <p>You can join the PVP discord server using the link below.</p>
        <div className="image-header cta">
          <Box>
            <Row>
              <Col>
                <div className="inner">
                  <h2>Check out the Prydwen PVP Discord!</h2>
                  <p>
                    Join the Prydwen PVP Discord to participate in the matches
                    or to just spectate them!
                  </p>
                  <Button
                    variant="white"
                    href="https://discord.gg/Rb4PKm5aWn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faDiscord} width="18" /> PVP Discord
                  </Button>
                </div>
              </Col>
            </Row>
          </Box>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default HSRPVPHub;

export const Head: React.FC = () => (
  <Seo
    title="PVP | Honkai: Star Rail | Prydwen Institute"
    description="Check out the PVP Draft mode created by us which allows you to challenge other players in Honkai: Star Rail!"
  />
);
